import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { setHome } from '../../actions/homes';

import Loading from '../common/Loading';
import withDragDropContext from './withDragDropContext';
import ImageDropTarget from './ImageDropTarget';
import ImageContainer from './ImageContainer';

import i18n from '../../i18n';
import ImageApi from '../../api/Image';
import Home from '../../models/Home';
import Analytics from '../../utils/analytics';
import withAnalytics from '../analytics/withAnalytics';
import RegularHomeStatusBlocker from '../home-status-blocker/RegularHomeStatusBlocker';

export class UploadList extends React.Component {
    static propTypes = {
        home: PropTypes.instanceOf(Home),
        images: PropTypes.arrayOf(PropTypes.object),
        minImages: PropTypes.number,
        maxImages: PropTypes.number,
        accept: PropTypes.string,
        onChange: PropTypes.func,
        shouldRefreshTopMenuProfile: PropTypes.bool,
        setHome: PropTypes.func,
        track: PropTypes.func.isRequired,
        handleSave: PropTypes.func,
        handleTurnOnline: PropTypes.func,
        enableTurnOnlinePopup: PropTypes.bool,
        source: PropTypes.string
    };

    static defaultProps = {
        minImages: 5,
        maxImages: 50,
        images: [],
        accept: 'capture=camera, image/jpeg, image/png',
        shouldRefreshTopMenuProfile: true,
        enableTurnOnlinePopup: false
    };

    constructor(props) {
        super(props);
        this.state = {
            images: this.reindexImages(this.props.images),
            uploading: false
        };
        this.openDialog = this.openDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.moveCard = this.moveCard.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.rotateImage = this.rotateImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    componentDidMount() {
        // dispatch "upload_list_updated" event
        document.dispatchEvent(
            new CustomEvent('upload_list_updated', {
                detail: {
                    images: this.state.images.length,
                    minImages: this.props.minImages
                }
            })
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.onChange && prevState.images.length != this.state.images.length) {
            this.props.onChange(this.state.images);
        }
        // dispatch "upload_list_updated" event
        document.dispatchEvent(
            new CustomEvent('upload_list_updated', {
                detail: {
                    images: this.state.images.length,
                    minImages: this.props.minImages
                }
            })
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            images: this.reindexImages(nextProps.images)
        });
    }

    reindexImages(images) {
        // 0-index the image array based on order display
        if (images && images.length > 0) {
            images = JSON.parse(JSON.stringify(images));

            // Reorder images
            images.sort((a, b) => a.order_display - b.order_display);

            // Reindex from 0
            images.forEach((image, index) => {
                image.order_display = index;
            });
        }

        return images;
    }

    handleChange(e) {
        const { files } = e.target;
        this.uploadFiles(files);
        // reset form and input file value
        e.target.parentNode.reset();
    }

    moveCard(id, hoverIndex) {
        const currentIndex = this.state.images.findIndex((image) => image.id === id);
        // image not found
        if (currentIndex === -1) {
            return false;
        }
        // didn’t move
        if (currentIndex === hoverIndex) {
            return false;
        }
        // prevent overflow
        if (hoverIndex >= this.state.images.length) {
            return false;
        }
        const images = JSON.parse(JSON.stringify(this.state.images));
        // moves items
        images.splice(hoverIndex, 0, images.splice(currentIndex, 1)[0]);
        // update items’s order_display
        images.forEach((img, index) => {
            img.order_display = index;
        });
        // update display list
        this.setState({
            images
        });

        return true;
    }

    updateOrder() {
        // update images order
        const images = this.state.images.map((img) => _.pick(img, ['id', 'order_display', 'rotate', 'type']));
        ImageApi.sort({ images });
    }

    renderTarget(i) {
        return (
            <ImageDropTarget
                key={i}
                index={i}
                moveCard={this.moveCard}
                updateOrder={this.updateOrder}
                openDialog={this.openDialog}
                uploadFiles={this.uploadFiles}
            >
                {this.renderImage(i)}
            </ImageDropTarget>
        );
    }

    renderImage(i) {
        const image = this.state.images.find((img) => img.order_display == i);
        if (image) {
            return <ImageContainer image={image} rotate={this.rotateImage} delete={this.deleteImage} />;
        }
    }

    uploadFiles(files) {
        const { home, enableTurnOnlinePopup } = this.props;
        const update = [];
        // create promises list with each upload file requests
        for (let i = 0; i < files.length; i++) {
            update.push(ImageApi.post('home', home.id, files[i]));
        }
        this.setState({ uploading: true });
        // sort files when all uploaded and update display list
        return Promise.all(update).then((results) => {
            // iterable tracking
            try {
                Analytics.trackIterable('HomePhotosUpdated', {
                    email: this.props.home.get('user').get('email'),
                    homeId: this.props.home.get('id')
                });
                this.pushGTMTracking(this.props.source);
            } catch (error) {
                console.error('Error while tracking Iterable - HomePhotosUpdated', error);
            }

            if (this.props.shouldRefreshTopMenuProfile) {
                // Update store with new completion rate
                const completionRate = Math.max(...results.map((result) => result.home.completion_rate));
                const prevCompletionRate = home.get('completion_rate');
                const eightyCompletionDate = home.get('eighty_completion_date');
                if (enableTurnOnlinePopup) {
                    if (this.props.home.get('status') === Home.STATUS_OFFLINE) {
                        if (
                            (completionRate <= 80 && completionRate >= 80) ||
                            (completionRate >= 80 && completionRate <= 100 && completionRate >= 80)
                        ) {
                            RegularHomeStatusBlocker(
                                completionRate,
                                this.props.home.get('status'),
                                this.props.home.get('user'),
                                this.props.home,
                                this.props.handleTurnOnline,
                                this.props.handleSave,
                                true
                            );
                            if (!eightyCompletionDate) {
                                Analytics.trackGTM('Home 80%', { home_id: home.get('id') });
                            }
                            // We should add a condition to prevent have duplicate trigger events
                            Analytics.trackGTM('MixpanelHomeQualified', {
                                userStatus: this.props.home.get('user').getStatus()
                            });
                        }
                    }
                }
                if (prevCompletionRate < 100 && completionRate === 100) {
                    this.props.track('House 100%');
                }
                home.set('completion_rate', completionRate);
                this.props.setHome(home);
            }
            this.setState(
                (prevState) => {
                    const nextImages = prevState.images.concat(results);
                    nextImages.sort((a, b) => a.order_display - b.order_display);
                    return {
                        images: nextImages,
                        uploading: false
                    };
                },
                () => {
                    const progressPercentage = Math.max(
                        ...results.map((result) => result.home.completion_rate)
                    );
                    this.updateProgress(progressPercentage);
                }
            );
        });
    }

    updateProgress(percentage) {
        $('.panel-filling .progress-filling .progress-bar-success').css('width', `${percentage}%`);
        $(`.filling-user-${this.userId}, .progress-filling span`).text(`${percentage}%`);
    }

    rotateImage(image, rotation) {
        const rotate = (image.rotate || 0) + rotation;
        // update image rotation
        return ImageApi.update(image.id, { rotate }).then((response) => {
            // update images and display list
            this.setState({
                images: this.state.images.map((img) => {
                    if (img.id == image.id) {
                        return response;
                    }
                    return img;
                })
            });
        });
    }

    deleteImage(image) {
        const promise = ImageApi.delete(image.id);
        promise.then((data) => {
            this.pushGTMTracking(this.props.source);
            // update display list
            this.setState(
                (prevState) => {
                    // remove image from display list
                    let images = prevState.images.filter((img) => img.id != image.id);
                    // reindex
                    images = this.reindexImages(images);

                    return {
                        images
                    };
                },
                () => {
                    this.updateProgress(data.home.completion_rate);
                }
            );
        });
    }

    openDialog() {
        $(this.inputFile).trigger('click');
    }

    render() {
        if (!this.props.home) {
            return <Loading />;
        }
        let remaining = 0;
        if (this.state.images.length) {
            remaining = this.props.minImages - this.state.images.length;
        }
        // render targets with an empty target if there is less images than maxImages
        const targets = [];
        const imageLength = this.state.images.length;
        const itemsLength = Math.max(
            Math.min(imageLength + 1, Math.max(imageLength, this.props.maxImages)),
            this.props.minImages
        );
        for (let i = 0; i < itemsLength; i++) {
            targets.push(this.renderTarget(i));
        }
        return (
            <section className="upload upload-list">
                <div className="upload-action">
                    <form className="upload-form text-center" encType="multipart/form-data">
                        <button
                            type="button"
                            className={classNames('btn btn-primary btn-ajax', {
                                sending: this.state.uploading
                            })}
                            onClick={this.openDialog}
                        >
                            <i className="icon-home-camera camera-icon" aria-hidden="true"></i>
                            <span>{i18n.t('upload:uploadForm_addImages')}</span>
                        </button>
                        <input
                            ref={(input) => {
                                this.inputFile = input;
                            }}
                            id="inputUploadFile"
                            type="file"
                            name="files[]"
                            multiple
                            accept={this.props.accept}
                            onChange={this.handleChange}
                        />
                    </form>
                    {remaining > 0 && (
                        <p className="well-done">
                            {i18n.t('upload:uploadForm_wellDone', { count: remaining })}
                        </p>
                    )}
                </div>
                <div className="row upload-images">{targets}</div>
            </section>
        );
    }

    pushGTMTracking(source) {
        if (source === 'home-edit') {
            Analytics.trackGTM('HomeEdition', {
                event_data: {
                    action: 'click',
                    text: 'photo_changed',
                    area: 'home edit'
                }
            });
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    setHome: bindActionCreators(setHome, dispatch)
});

export const UploadListWithDragAndDrop = withDragDropContext(UploadList);
export default compose(connect(null, mapDispatchToProps), withAnalytics)(UploadListWithDragAndDrop);
