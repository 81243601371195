import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import { Divider } from '@homeexchange/design';
import i18n from '../../i18n';
import Availability from '../../models/Availability';
import Home from '../../models/Home';
import User from '../../models/User';
import Analytics from '../../utils/analytics';
import EditCalendar from '../calendar/EditCalendar';
import HomeEditMinNights from '../home/edit/HomeEditMinNights';
import CalendarCaption from './CalendarCaption';
import { fetchHomes } from '../../actions/homes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const CalendarSwitchCaption = React.lazy(() => import('../calendar/CalendarSwitchCaption'));
export class EditCalendarWrapper extends React.PureComponent {
    static propTypes = {
        homeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        home: PropTypes.instanceOf(Home),
        updateHome: PropTypes.func,
        nbMonths: PropTypes.number,
        maxNbMonths: PropTypes.number,
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
        fetchAvailabilities: PropTypes.func.isRequired,
        fetchHomes: PropTypes.func.isRequired,
        addAvailability: PropTypes.func.isRequired,
        updateAvailabilities: PropTypes.func.isRequired,
        classnames: PropTypes.arrayOf(PropTypes.string),
        mode: PropTypes.string,
        user: PropTypes.instanceOf(User),
        onChange: PropTypes.func,
        updatedCalendar: PropTypes.instanceOf(moment)
    };

    state = {
        nbMonths: 6,
        nbMonthsToDisplay: 6,
        hasReservedAvailability: false,
        isMyHome: false,
        firstname: '',
        warningTitle: null,
        warningDescription: null,
        errorTitle: null,
        errorDescription: null,
        datesSelected: false
    };

    constructor(props) {
        super(props);
        this.showMoreMonths = this.showMoreMonths.bind(this);
        this.setAvailability = this.setAvailability.bind(this);
        this.computeNumberOfMonthsToDisplay = this.computeNumberOfMonthsToDisplay.bind(this);
        this.resizeLastRowCalendars = this.resizeLastRowCalendars.bind(this);
        this.onResize = _.debounce(this.onResize.bind(this), 500);
        this.state.nbMonths = props.nbMonths;
        this.state.nbMonthsToDisplay = props.nbMonths;
    }

    componentDidMount() {
        this.updateHasReservedAvailability(this.props.availabilities);
        this.adaptNumberOfMonthsToDisplay();

        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        if (this.errorTimeout) {
            clearTimeout(this.errorTimeout);
        }
        window.removeEventListener('resize', this.onResize);
    }

    componentDidUpdate() {
        this.updateHasReservedAvailability(this.props.availabilities);
        this.resizeLastRowCalendars();
    }

    onResize() {
        this.adaptNumberOfMonthsToDisplay();
    }

    /**
     * Update hasReservedAvailabilityState based on current availabilities
     * @param {Availabilities[]} availabilities
     */
    updateHasReservedAvailability(availabilities) {
        let hasReservedAvailability = false;
        availabilities.forEach((availability) => {
            if (availability.get('type') === Availability.BOOKED.type) {
                hasReservedAvailability = true;
            }
        });
        this.setState({
            hasReservedAvailability
        });
    }

    setAvailability(startOn, endOn, type) {
        const data = {
            type,
            start_on: startOn,
            end_on: endOn
        };

        if (this.errorTimeout) {
            clearTimeout(this.errorTimeout);
        }

        // Remove previous errors
        this.clearErrors();

        this.props.addAvailability(this.props.homeId, data).then(
            (response) => {
                // send calendar updated trackind to iterable
                try {
                    Analytics.trackIterable('UpdateCalendar', {
                        email: this.props.user.get('email'),
                        homeId: this.props.homeId
                    });
                } catch (error) {
                    console.error('Error while tracking Iterable - UpdateCalendar', error);
                }
                this.props.fetchHomes();
                if (response && response.data) {
                    const availabilities = response.data;
                    const hasDifferences = EditCalendar.availabilitiesHasDifferences(
                        availabilities,
                        this.props.availabilities
                    );
                    if (hasDifferences) {
                        this.setState({
                            warningTitle: i18n.t('home:calendar.mismatched_availabilities_title'),
                            warningDescription: i18n.t('home:calendar.mismatched_availabilities_description')
                        });
                        this.setErrorTimeout(20000);
                    }
                }
            },
            () => {
                // Show error message and revert availabilities
                this.setState({
                    errorTitle: i18n.t('common:problem_occured'),
                    errorDescription: i18n.t('home:calendar.error_availability')
                });
                this.props.fetchAvailabilities(this.props.homeId);
                this.setErrorTimeout();
            }
        );
    }

    clearErrors() {
        this.setState({
            warningTitle: null,
            warningDescription: null,
            errorTitle: null,
            errorDescription: null
        });
    }

    setErrorTimeout(timeout = 60000) {
        // Add a timeout to clear the error and warning message
        this.errorTimeout = setTimeout(() => {
            this.setState({
                warningTitle: null,
                warningDescription: null,
                errorTitle: null,
                errorDescription: null
            });
        }, timeout);
    }

    showNoAvailabilitiesMessage() {
        return (
            this.props.availabilities.filter((availability) =>
                Availability.availableTypes.includes(availability.get('type'))
            ).length === 0
        );
    }

    showMoreMonths() {
        if (this.state.nbMonths < this.props.maxNbMonths) {
            this.setState(
                {
                    nbMonths: this.state.nbMonths + this.props.nbMonths
                },
                () => {
                    this.adaptNumberOfMonthsToDisplay();
                }
            );
        }
    }

    getNumberOfMonthsPerRow() {
        if (!this.container) {
            return;
        }

        const calendarDivs = this.container.querySelectorAll('.k-calendar');
        let numberOfMonthsPerRow = 0;

        if (calendarDivs.length === 0) {
            return;
        }

        const firstYPosition = calendarDivs[0].getBoundingClientRect().top;

        for (let i = 0, l = calendarDivs.length; i < l; i++) {
            if (calendarDivs[i].getBoundingClientRect().top !== firstYPosition) {
                break;
            }
            numberOfMonthsPerRow++;
        }

        return numberOfMonthsPerRow;
    }

    adaptNumberOfMonthsToDisplay() {
        this.computeNumberOfMonthsToDisplay(() => {
            const event = new CustomEvent('calendar_adapted');
            document.dispatchEvent(event);
        });
    }

    computeNumberOfMonthsToDisplay(cb) {
        const numberOfMonthsPerRow = this.getNumberOfMonthsPerRow();

        if (this.state.nbMonths < this.props.maxNbMonths) {
            let additionalMonths = numberOfMonthsPerRow;
            // We need to add one line that is partly hidden beneath the "show more months" button
            if (this.props.maxNbMonths - this.state.nbMonths < numberOfMonthsPerRow) {
                additionalMonths = this.props.maxNbMonths - this.state.nbMonths;
            }
            this.setState(
                {
                    nbMonthsToDisplay: this.state.nbMonths + additionalMonths
                },
                cb
            );
        } else {
            this.setState(
                {
                    nbMonthsToDisplay: this.state.nbMonths
                },
                cb
            );
        }
    }

    resizeLastRowCalendars() {
        if (!this.container) {
            return 0;
        }
        const numberOfMonthsPerRow = this.getNumberOfMonthsPerRow();
        const calendarDivs = Array.from(this.container.querySelectorAll('.k-calendar'));
        const calendarDivsLength = calendarDivs.length;

        calendarDivs.forEach((calendar) => {
            $(calendar).removeClass('last-row');
        });

        if (this.shouldShowMoreMonthsButton()) {
            const lastCalendarDivs = calendarDivs.slice(calendarDivsLength - numberOfMonthsPerRow);
            lastCalendarDivs.forEach((calendar) => {
                $(calendar).addClass('last-row');
            });
        }
    }

    shouldShowMoreMonthsButton() {
        return this.state.nbMonths < this.props.maxNbMonths;
    }

    render() {
        let extendedAvailabilities = [...this.props.availabilities];
        if (this.props.home?.toggleContactAllowed() === Home.CONTACT_NOT_ALLOWED_ON_UNAVAILABLE) {
            extendedAvailabilities = extendedAvailabilities.filter(
                (availability) => availability.get('type') !== Availability.RESERVED.type
            );
        }
        return (
            <div className="home-calendar" ref={(ref) => (this.container = ref)}>
                {this.props.mode === 'home-edit' && (
                    <>
                        <HomeEditMinNights
                            onChange={this.props.onChange}
                            home={this.props.home}
                            updateHome={this.props.updateHome}
                        />
                        <Divider smallSpace />
                    </>
                )}
                <Suspense fallback={<div>{`${i18n.t('common:loading')}...`}</div>}>
                    <CalendarSwitchCaption
                        showReserved={this.state.hasReservedAvailability}
                        showNoAvailabilitiesMessage={this.showNoAvailabilitiesMessage()}
                        warningTitle={this.state.warningTitle}
                        warningDescription={this.state.warningDescription}
                        errorTitle={this.state.errorTitle}
                        errorDescription={this.state.errorDescription}
                        isOwner={true}
                        mode={this.props.mode}
                        updatedCalendar={this.props.updatedCalendar}
                    />
                </Suspense>
                <CalendarCaption
                    key="calendar-caption"
                    isOwner={true}
                    dropdown
                    openDropdown={!this.props.updatedCalendar && this.props.mode !== 'signup'}
                    verticalDisplay={this.props.mode === 'signup'}
                />
                <EditCalendar
                    key={`edit-calendar-${this.props.home?.toggleContactAllowed().toString()}`}
                    nbMonths={this.state.nbMonthsToDisplay}
                    availabilities={extendedAvailabilities}
                    mode="range"
                    showBookingDetails={true}
                    setAvailability={this.setAvailability}
                    updateAvailabilities={this.props.updateAvailabilities}
                    classname={this.props.classnames}
                    minimumNights={this.props.home?.getMinNights()}
                    contactAllowed={this.props.home?.toggleContactAllowed()}
                />
                {this.shouldShowMoreMonthsButton() && (
                    <div className="btn-more-months-container">
                        <div className="btn-more-months-overlay"></div>
                        <button
                            type="button"
                            className="btn btn-action-large-v2 btn-more-months"
                            onClick={this.showMoreMonths}
                        >
                            {i18n.t('home:calendar.show_more_months')}
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    fetchHomes: bindActionCreators(fetchHomes, dispatch)
});

export default connect(null, mapDispatchToProps)(EditCalendarWrapper);
