import { useState, useEffect, useContext } from 'react';
import ProductApi from '../api/Product';
import { currencies, CurrencyContext } from '../components/currency';

const usePrice = () => {
    const [allPrices, setAllPrices] = useState();
    const [price, setPrice] = useState();
    const [symbol, setSymbol] = useState();

    const { currency } = useContext(CurrencyContext);

    useEffect(() => {
        ProductApi.getOldPrice()
            .then((response) => response.json())
            .then((data) => setAllPrices(data));
    }, []);

    useEffect(() => {
        if (allPrices) {
            if (allPrices[currency]) {
                if (currencies[currency]) {
                    setPrice(allPrices[currency].YEAR_1ST);
                    setSymbol(currencies[currency]);
                }
            }
        }
    }, [allPrices]);

    return { price, symbol };
};

export default usePrice;
