import Api from '../api/Api';
import Availability from '../models/Availability';

import { setCalendarUpdatedAt } from './calendar';

export const SET_AVAILABILITIES = 'SET_AVAILABILITIES';

export function setAvailabilities(homeId, availabilities) {
    return {
        type: SET_AVAILABILITIES,
        payload: {
            [homeId]: availabilities.map((availability) => new Availability(availability))
        }
    };
}

export function handleAvailabilitiesResponse(response, dispatch, homeId) {
    if (response && response.data) {
        const updatedAt = response.updated_at ? moment.parseZone(response.updated_at) : null;
        dispatch(setCalendarUpdatedAt(homeId, updatedAt));
        // Create Availability objects
        const availabilities = response.data.map((availability) => new Availability(availability));
        sessionStorage.setItem(`availabilities_${homeId}`, JSON.stringify(availabilities));
        dispatch(setAvailabilities(homeId, availabilities));
        response.data = availabilities;
    }
    return response;
}

export function fetchAvailabilities(homeId) {
    return (dispatch) =>
        Api.Home.getAvailabilities(homeId).then((response) =>
            handleAvailabilitiesResponse(response, dispatch, homeId)
        );
}

export function addAvailability(homeId, data) {
    return (dispatch) =>
        Api.Home.setAvailability(homeId, data).then((response) =>
            handleAvailabilitiesResponse(response, dispatch, homeId)
        );
}
