import currencyformatterJs from 'currencyformatter.js';

import i18n from '../i18n';
/* istanbul ignore if  */
if (!global.Intl) {
    require('intl');
    require('intl/locale-data/jsonp/de');
    require('intl/locale-data/jsonp/en');
    require('intl/locale-data/jsonp/es');
    require('intl/locale-data/jsonp/fr');
    require('intl/locale-data/jsonp/it');
    require('intl/locale-data/jsonp/nl');
    require('intl/locale-data/jsonp/pt');
}

export default global.Intl;

export const NumberFormatter = new Intl.NumberFormat(i18n.language, {
    style: 'decimal'
});

export const CurrencyFormatter = {
    format(value = 0, currency = 'EUR', options = {}) {
        const result = currencyformatterJs.format(value, {
            currency,
            locale: i18n.language,
            ...options
        });
        if (typeof result === 'string') {
            return result.replace(/([,.]0{1,2})(\D+)?$/, '$2');
        }
        return result;
    },

    parseCurrency(str) {
        str = str.split('(')[0].trim();

        const symbols = currencyformatterJs.symbols;

        const symbolRegex = new RegExp(
            Object.values(symbols)
                .map((s) => s.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'))
                .join('|')
        );

        const symbolMatch = str.match(symbolRegex);
        const symbol = symbolMatch ? symbolMatch[0] : '$';
        const currency = Object.keys(symbols).find((key) => symbols[key] === symbol) || 'USD';

        // Extract number using regex
        const match = str.match(/\d{1,3}([,.\s]\d{3})*([,.]\d{1,2})?/);
        const numStr = match ? match[0] : '0';

        // Parse the value
        const value = parseFloat(numStr.replace(/[,\s]/g, '').replace(/\./g, ''));

        return { value, currency, symbol };
    }
};

export const PercentageFormatter = new Intl.NumberFormat(i18n.language, {
    style: 'percent',
    maximumFractionDigits: 2
});

export const getBestDescription = (descriptions, userLocale) => {
    const noDescriptionFound = {
        locale: i18n.language,
        description: null
    };

    if (!descriptions || descriptions.length === 0) {
        return noDescriptionFound;
    }

    if (
        descriptions[i18n.language] &&
        Object.keys(descriptions[i18n.language]).find(
            (attr) => !_.isEmpty(_.result(descriptions[i18n.language], attr))
        )
    ) {
        // Try to get the description in the page language
        return {
            locale: i18n.language,
            description: descriptions[i18n.language]
        };
    } else if (
        userLocale &&
        descriptions[userLocale] &&
        Object.keys(descriptions[userLocale]).find(
            (attr) => !_.isEmpty(_.result(descriptions[userLocale], attr))
        )
    ) {
        // Try to get the description in the user language
        return {
            locale: userLocale,
            description: descriptions[userLocale]
        };
    } else {
        // Return the first description available
        const firstDescriptionLocale = Object.keys(descriptions).find(
            (locale) =>
                !_.isUndefined(descriptions[locale]) &&
                Object.keys(descriptions[locale]).find(
                    (attr) => !_.isEmpty(_.result(descriptions[locale], attr))
                )
        );

        if (firstDescriptionLocale) {
            return {
                locale: firstDescriptionLocale,
                description: descriptions[firstDescriptionLocale]
            };
        } else {
            return noDescriptionFound;
        }
    }
};
